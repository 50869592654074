// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUserCheck, 
         IconSelect, 
         IconBasket, 
         IconMessages, 
         IconLayoutKanban, 
         IconMail, 
         IconCalendar, 
         IconNfc, 
         IconActivity, 
         IconBrandBooking, 
         IconFlag } from '@tabler/icons';

// constant
const icons = {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconSelect,
    IconActivity,
    IconBrandBooking,
    IconFlag
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application = {
    id: 'application',
    title: <FormattedMessage id="application" />,
    type: 'group',
    children: [
        // {
        //     id: 'users',
        //     title: <FormattedMessage id="users" />,
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'posts',
        //             title: <FormattedMessage id="social-profile" />,
        //             type: 'item',
        //             url: '/user/social-profile/posts'
        //         },
        //         {
        //             id: 'account-profile',
        //             title: <FormattedMessage id="account-profile" />,
        //             type: 'collapse',
        //             children: [
        //                 {
        //                     id: 'profile1',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="profile" /> 01
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '/user/account-profile/profile1'
        //                 },
        //                 {
        //                     id: 'profile2',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="profile" /> 02
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '/user/account-profile/profile2'
        //                 },
        //                 {
        //                     id: 'profile3',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="profile" /> 03
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '/user/account-profile/profile3'
        //                 }
        //             ]
        //         },
        //         {
        //             id: 'user-card',
        //             title: <FormattedMessage id="cards" />,
        //             type: 'collapse',
        //             children: [
        //                 {
        //                     id: 'card1',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="style" /> 01
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '/user/card/card1'
        //                 },
        //                 {
        //                     id: 'card2',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="style" /> 02
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '/user/card/card2'
        //                 },
        //                 {
        //                     id: 'card3',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="style" /> 03
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '/user/card/card3'
        //                 }
        //             ]
        //         },
        //         {
        //             id: 'user-list',
        //             title: <FormattedMessage id="list" />,
        //             type: 'collapse',
        //             children: [
        //                 {
        //                     id: 'list1',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="style" /> 01
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '/user/list/list1'
        //                 },
        //                 {
        //                     id: 'list2',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="style" /> 02
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '/user/list/list2'
        //                 }
        //             ]
        //         }
        //     ]
        // },
        {
            id: 'organization',
            title: <FormattedMessage id="organization" />,
            type: 'collapse',
            icon: icons.IconSelect,
            children: [
                {
                    id: 'organization-list',
                    title: <FormattedMessage id="organization-list" />,
                    type: 'item',
                    url: '/organization/list'
                }
            ]
        },
        {
            id: 'recruitment',
            title: <FormattedMessage id="recruitment" />,
            type: 'collapse',
            icon: icons.IconSelect,
            children: [
                {
                    id: 'candidate',
                    title: <FormattedMessage id="candidate" />,
                    type: 'item',
                    url: '/recruitment/candidate/list'
                },
                {
                    id: 'archive',
                    title: <FormattedMessage id="archive" />,
                    type: 'item',
                    url: '/recruitment/archive/list'
                },
            ]
        },
        // {
        //     id: 'customer',
        //     title: <FormattedMessage id="customer" />,
        //     type: 'collapse',
        //     icon: icons.IconBasket,
        //     children: [
                // {
                //     id: 'customer-list',
                //     title: <FormattedMessage id="customer-list" />,
                //     type: 'item',
                //     url: '/customer/customer-list',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'trial-list',
                //     title: <FormattedMessage id="trial-list" />,
                //     type: 'item',
                //     url: '/customer/trial-list',
                //     breadcrumbs: true
                // },
                // {
                //     id: 'subscription-list',
                //     title: <FormattedMessage id="subscription-list" />,
                //     type: 'item',
                //     url: '/customer/subscription-list',
                //     breadcrumbs: true
                // },
                // {
                //     id: 'group-list',
                //     title: <FormattedMessage id="group-list" />,
                //     type: 'item',
                //     url: '/customer/group-list',
                //     breadcrumbs: true
                // },
                // {
                //     id: 'order-list',
                //     title: <FormattedMessage id="order-list" />,
                //     type: 'item',
                //     url: '/customer/order-list',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'order-details',
                //     title: <FormattedMessage id="order-details" />,
                //     type: 'item',
                //     url: '/customer/order-details'
                // },
                // {
                //     id: 'product',
                //     title: <FormattedMessage id="product" />,
                //     type: 'item',
                //     url: '/customer/product',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'product-review',
                //     title: <FormattedMessage id="product-review" />,
                //     type: 'item',
                //     url: '/customer/product-review',
                //     breadcrumbs: false
                // }
        //     ]
        // },
        {
            id: 'booking',
            title: <FormattedMessage id="booking" />,
            type: 'collapse',
            icon: icons.IconBrandBooking,
            children: [
                {
                    id: 'trial-list',
                    title: <FormattedMessage id="trial-list" />,
                    type: 'item',
                    url: '/booking/trial-list',
                    breadcrumbs: true
                },
                {
                    id: 'subscription-list',
                    title: <FormattedMessage id="subscription-list" />,
                    type: 'item',
                    url: '/booking/subscription-list',
                    breadcrumbs: true
                },
                {
                    id: 'group-list',
                    title: <FormattedMessage id="group-list" />,
                    type: 'item',
                    url: '/booking/group-list',
                    breadcrumbs: true
                },
            ]
        },
        {
            id: 'class',
            title: <FormattedMessage id="class" />,
            type: 'collapse',
            icon: icons.IconActivity,
            children: [
                {
                    id: 'class-list',
                    title: <FormattedMessage id="class-list" />,
                    type: 'item',
                    url: '/class/list',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'seller',
            title: <FormattedMessage id="seller" />,
            type: 'collapse',
            icon: icons.IconUserCheck,
            children: [
                {
                    id: 'seller-create',
                    title: <FormattedMessage id="seller-create" />,
                    type: 'item',
                    url: '/seller/create',
                    breadcrumbs: false
                },
                {
                    id: 'seller-list',
                    title: <FormattedMessage id="seller-list" />,
                    type: 'item',
                    url: '/seller/list',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'chat',
            title: <FormattedMessage id="chat" />,
            type: 'item',
            icon: icons.IconMessages,
            url: '/app/chat'
        },
        {
            id: 'kanban',
            title: 'Kanban',
            type: 'item',
            icon: icons.IconLayoutKanban,
            url: '/app/kanban'
        },
        {
            id: 'mail',
            title: <FormattedMessage id="mail" />,
            type: 'item',
            icon: icons.IconMail,
            url: '/app/mail'
        },
        {
            id: 'calendar',
            title: <FormattedMessage id="calendar" />,
            type: 'item',
            url: '/app/calendar',
            icon: icons.IconCalendar,
            breadcrumbs: false
        },
        {
            id: 'contact',
            title: <FormattedMessage id="contact" />,
            type: 'collapse',
            icon: icons.IconNfc,
            children: [
                {
                    id: 'c-card',
                    title: <FormattedMessage id="cards" />,
                    type: 'item',
                    url: '/app/contact/c-card',
                    breadcrumbs: false
                },
                {
                    id: 'c-list',
                    title: <FormattedMessage id="list" />,
                    type: 'item',
                    url: '/app/contact/c-list',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'blog',
            title: <FormattedMessage id="blog" />,
            type: 'collapse',
            icon: icons.IconBasket,
            children: [
                {
                    id: 'posts',
                    title: <FormattedMessage id="posts" />,
                    type: 'item',
                    url: '/blog/posts'
                },
                {
                    id: 'post-create',
                    title: <FormattedMessage id="post-create" />,
                    type: 'item',
                    url: '/blog/post-create',
                    breadcrumbs: false
                },
                {
                    id: 'post-details',
                    title: <FormattedMessage id="post-details" />,
                    type: 'item',
                    url: '/blog/post-details/default',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'e-commerce',
            title: <FormattedMessage id="e-commerce" />,
            type: 'collapse',
            icon: icons.IconBasket,
            children: [
                {
                    id: 'products',
                    title: <FormattedMessage id="products" />,
                    type: 'item',
                    url: '/e-commerce/products'
                },
                // {
                //     id: 'product-details',
                //     title: <FormattedMessage id="product-details" />,
                //     type: 'item',
                //     url: '/e-commerce/product-details/default',
                //     breadcrumbs: false
                // },
                {
                    id: 'product-list',
                    title: <FormattedMessage id="product-list" />,
                    type: 'item',
                    url: '/e-commerce/product-list',
                    breadcrumbs: false
                },
                {
                    id: 'checkout',
                    title: <FormattedMessage id="checkout" />,
                    type: 'item',
                    url: '/e-commerce/checkout'
                }
            ]
        },
        {
            id: 'event',
            title: <FormattedMessage id="event" />,
            type: 'collapse',
            icon: icons.IconFlag,
            children: [
                {
                    id: 'event-list',
                    title: <FormattedMessage id="event-list" />,
                    type: 'item',
                    url: '/event/list',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default application;
